import styled from "styled-components";

export const OTPStyle = styled.section`
    .footer {
        display: flex;
        margin-top: 32px;
        justify-content: space-between;

        .timer {
            font-size: 1.4em;
            font-weight: 600;
            color: #5d6167;
        }

        button {
            color: #67adc8;
            font-size: 1.4em;
            font-weight: 600;
        }
    }
`;
